import "../../assets/css/Galerie.css";
import "../../assets/css/GalerieResp.css";
import img1 from "../../assets/img/PHOTOPAPA/1.jpg";
import img2 from "../../assets/img/PHOTOPAPA/2.png";
import img3 from "../../assets/img/PHOTOPAPA/3.jpg";
import img4 from "../../assets/img/PHOTOPAPA/4.png";
import img5 from "../../assets/img/PHOTOPAPA/5.jpg";
import img6 from "../../assets/img/PHOTOPAPA/6.jpg";
import img7 from "../../assets/img/PHOTOPAPA/7.jpg";
import img8 from "../../assets/img/PHOTOPAPA/8.jpg";
import img9 from "../../assets/img/PHOTOPAPA/9.jpg";
import img10 from "../../assets/img/PHOTOPAPA/10.png";
import video11 from "../../assets/videos/11.mp4";
import React, { useState, useEffect } from "react";
import ModalPicture from "./ModalPicture";

const Galerie = ({ scrollPosition }) => {
	const [modalOpen, setModalOpen] = useState(false);
	useEffect(() => {
		if (modalOpen) {
			// Ajoute une classe 'no-scroll' au body pour bloquer le défilement
			document.body.classList.add("no-scroll");
		} else {
			// Retire la classe 'no-scroll' pour permettre à nouveau le défilement
			document.body.classList.remove("no-scroll");
		}

		// Cleanup function pour s'assurer que la classe est retirée si le composant est démonté
		return () => {
			document.body.classList.remove("no-scroll");
		};
	}, [modalOpen]);

	const images = [
		img1,
		img2,
		img3,
		img4,
		img5,
		img6,
		img7,
		img8,
		img9,
		img10,
		video11,
	];

	const [currentImage, setCurrentImage] = useState(0);

	const openSlider = (index) => {
		setCurrentImage(index);
		setModalOpen(true);
	};

	const closeSlider = () => setModalOpen(false);

	const nextImage = () => {
		setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
	};

	const prevImage = () => {
		setCurrentImage(
			(prevIndex) => (prevIndex - 1 + images.length) % images.length
		);
	};

	return (
		<section id="galerie">
			<div className="galerieTexte">
				<hr className="separatorSection" />
				<h3>Galerie</h3>
			</div>

			<section id="galerieContainer">
				<>
					<div
						className="photo"
						style={{ gridArea: "photo1" }}
						onClick={() => openSlider(0)}
					>
						<img src={img1} alt="Photo 1" />
					</div>
					<div
						className="photo"
						style={{ gridArea: "photo2" }}
						onClick={() => openSlider(1)}
					>
						<img src={img2} alt="Photo 2" />
					</div>
					<div
						className="photo"
						style={{ gridArea: "photo3" }}
						onClick={() => openSlider(2)}
					>
						<img src={img3} alt="Photo 3" />
					</div>
					<div
						className="photo"
						style={{ gridArea: "photo4" }}
						onClick={() => openSlider(3)}
					>
						<img src={img4} alt="Photo 9" />
					</div>
					<div
						className="photo"
						style={{ gridArea: "photo5" }}
						onClick={() => openSlider(4)}
					>
						<img src={img5} alt="Photo 10" />
					</div>
					<div
						className="photo"
						style={{ gridArea: "photo6" }}
						onClick={() => openSlider(5)}
					>
						<img src={img6} alt="Photo 11" />
					</div>
					<div
						className="photo"
						style={{ gridArea: "photo7" }}
						onClick={() => openSlider(6)}
					>
						<img src={img7} alt="Photo 9" />
					</div>
					<div
						className="photo"
						style={{ gridArea: "photo8" }}
						onClick={() => openSlider(7)}
					>
						<img src={img10} alt="Photo 10" />
					</div>

					<div
						className="photo"
						style={{ gridArea: "photo9" }}
						onClick={() => openSlider(8)}
					>
						<img src={img9} alt="Photo 9" />
					</div>
					<div
						className="photo"
						style={{ gridArea: "photo10" }}
						onClick={() => openSlider(9)}
					>
						<img src={img8} alt="Photo 10" />
					</div>
					<div
						className="photo"
						style={{ gridArea: "photo11" }}
						onClick={() => openSlider(10)}
					>
						<video autoPlay={true} preload="auto" muted={true} loop>
							<source src={video11} type="video/mp4" />
						</video>
					</div>

					{
						<ModalPicture
							images={images}
							isOpen={modalOpen}
							close={closeSlider}
							currentImage={currentImage}
							nextImage={nextImage}
							prevImage={prevImage}
						/>
					}
				</>
			</section>
		</section>
	);
};

export default Galerie;
