import React, { useState } from "react";
import "../../assets/css/ModalPicture.css";
import "../../assets/css/ModalPictureResp.css";
const ModalPicture = ({
	images,
	isOpen,
	close,
	currentImage,
	nextImage,
	prevImage,
}) => {
	const currentItem = images[currentImage];
	const isVideo = currentItem && currentItem.endsWith(".mp4"); // Vérifier si l'URL de l'élément se termine par .mp4

	return (
		<section id="modalPicture" style={{ display: isOpen ? "block" : "none" }}>
			<div className="modalPicture-content">
				<span className="close" onClick={close}>
					&times;
				</span>
				{isVideo ? (
					<video controls autoplay loop muted id="videoModal">
						<source src={currentItem} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				) : (
					<img
						className="imgModalPicture"
						src={currentItem}
						alt={`Image ${currentImage}`}
					/>
				)}
				<button className="prev" onClick={prevImage}>
					&#10094;
				</button>
				<button className="next" onClick={nextImage}>
					&#10095;
				</button>
			</div>
		</section>
	);
};

export default ModalPicture;
