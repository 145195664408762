import "./assets/css/App.css";
import React, { useEffect, useState } from "react";
import Header from "./components/Header.js";
import Main from "./components/Main/Main.js";
import Footer from "./components/Footer.js";

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    setScrollPosition(currentPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Header />
      <Main scrollPosition={scrollPosition} />
      <Footer scrollPosition={scrollPosition} />
    </>
  );
}

export default App;
