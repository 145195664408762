import "../assets/css/Footer.css";
import "../assets/css/FooterResp.css";
import Contact from "./Contact";
import imgFin from "../assets/img/f6.png";
import imgFond from "../../src/assets/img/c1.png";
const Footer = ({ scrollPosition }) => {
	return (
		<>
			<div>
				<img src={imgFond} alt="" id="imageFond1" />
			</div>

			<section id="footer">
				<Contact />
				<div>
					<img src={imgFin} alt="" id="imgFooter" />
				</div>

				<div id="ruban">
					<span
						onClick={() => {
							window.scrollTo(0, 4520);
						}}
					>
						{/* Me contacter */}
					</span>

					<span>© PG 2024</span>
				</div>
			</section>
		</>
	);
};

export default Footer;
