import "../assets/css/Header.css";
import "../assets/css/HeaderResp.css";
import React, { useEffect, useState } from "react";
import video from "../assets/videos/tree.mp4";
import logo from "../assets/img/logoWhite.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
function Header() {
	return (
		<header>
			<section>
				<nav id="navBar">
					<a
						href="#Apropos"
						onClick={() => {
							window.scrollTo(0, 630);
						}}
					>
						A propos
					</a>
					<a
						href="#services"
						onClick={() => {
							window.scrollTo(0, 3109);
						}}
					>
						Services
					</a>
					<img src={logo} alt="logo" />
					<a href="#galerie">Galerie</a>
					<a href="#contact">Contact</a>
				</nav>
				<div id="videoContainer">
					<h1>Travaux en Haute Altitude</h1>
					<h2>
						Spécialiste qualifié en travaux d'alpiniste acrobatique
						<br />
						Installation de matériel de sécurité
						<br />
						Élagage et Abattage
					</h2>

					<a href="#aPropos">
						<i>
							<FontAwesomeIcon icon={faAnglesDown} />
						</i>
					</a>

					<video
						autoPlay={true}
						preload="auto"
						muted={true}
						loop
						id="videoHome"
						playsInline
					>
						<source src={video} type="video/mp4" />
					</video>
				</div>
			</section>
		</header>
	);
}

export default Header;
