import "../assets/css/ModalContact.css";
import send from "../assets/img/send.png";
const ModalContact = ({ isOpen, close }) => {
	return (
		<>
			{isOpen && (
				<section id="modal" style={{ display: isOpen ? "block" : "none" }}>
					<div className="modal-content">
						<span className="close" onClick={close}>
							&times;
						</span>
						<img src={send} alt="" />
						<p>
							Merci pour votre message, je vous répondrai dans les plus brefs
							délais.
						</p>
						<p id="textBye">À bientôt !</p>
					</div>
				</section>
			)}
		</>
	);
};

export default ModalContact;
