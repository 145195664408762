import "../../assets/css/APropos.css";
import "../../assets/css/AProposResp.css";
import PG from "../../assets/img/profil.jpg";
import photoApropos1 from "../../assets/img/apropos1.png";
import photoApropos2 from "../../assets/img/apropos2.png";
import React, { useRef, useEffect, useState } from "react";
import video from "../../assets/videos/presentation.mp4";
function APropos({ scrollPosition }) {
	return (
		<section id="aPropos">
			{console.log(scrollPosition)}
			<section id="containerPresentation">
				{scrollPosition >= 0 && (
					<div id="containerAPropos">
						<div className="presentation">
							<div className="containerNom">
								<div className="prenom">
									<h3>Pierre</h3>
								</div>
								<div className="nom">
									<h3>Gendrel</h3>
								</div>
							</div>
						</div>
						{scrollPosition >= 0 && (
							<div className="container">
								<img className="photo-circle" src={PG} />
								<div className="outer-circle">
									<div className="small-circle small-circle-1"></div>
									<div className="small-circle small-circle-2"></div>
								</div>
								<div class="vertical-wave-separator"></div>
							</div>
						)}
						{scrollPosition >= 0 && (
							<div>
								<p className="presentationTexte">
									Passionné par l'élagage et l'abattage d'arbres depuis
									maintenant 37 ans, j'ai développé une expertise approfondie en
									taille douce et en abattage dangereux.
									<br />
									<br />
									La taille douce, pour moi, va au-delà d'une simple technique,
									c'est une véritable passion. La sélection minutieuse des
									branches, la division, l'allègement : chaque arbre a son
									propre langage, et je suis là pour le comprendre et le
									sublimer.
								</p>
							</div>
						)}
					</div>
				)}
			</section>
			{scrollPosition >= 0 && (
				<section id="containerPresentation2">
					<img className="photo-circle2" src={photoApropos1} />
					<img className="photo-circle3" src={photoApropos2} />
					<p className="presentationTexte2">
						Je me suis spécialisé dans le soin des arbres difficiles. Chaque
						projet est une aventure, une occasion d'appliquer mes compétences de
						manière unique. Vous avez un arbre délicat qui nécessite une
						attention particulière ? C'est là que ma passion et mon expérience
						entrent en jeu.
					</p>
					<div id="containerPointFort">
						{scrollPosition >= 0 && (
							<>
								<div className="aProposRow1">
									<div className="rowDiv1">
										<p className="aProposTitre">Respect de l'Environnement</p>
										<p className="aProposTexte">
											Je crois fermement en la préservation de notre
											environnement. Chacun de mes travaux est réalisé avec le
											plus grand respect pour la nature. Mon objectif est de
											promouvoir la santé des arbres tout en minimisant notre
											impact sur la planète.
										</p>
									</div>
									<div className="circle-presentation circle1"></div>
									<div className="rowDiv2"></div>
								</div>
							</>
						)}
						{scrollPosition >= 0 && (
							<div className="aProposRow2">
								<div className="rowDiv1"></div>
								<div className="circle-presentation circle2"></div>
								<div className="rowDiv2">
									<p className="aProposTitre">Sécurité</p>
									<p className="aProposTexte">
										Formé aux normes d'élagage les plus strictes, avant chaque
										intervention, une évaluation détaillée de l'arbre, du
										terrain, et des zones à risque pour les personnes est
										réalisée pour adapter précisément mes méthodes à chaque
										situation. Des balisages rigoureux protègent votre propriété
										et assurent la sécurité de tous.
									</p>
								</div>
							</div>
						)}
						{scrollPosition >= 0 && (
							<div className="aProposRow1">
								<div className="rowDiv1">
									<p className="aProposTitre">Conseils Personnalisés</p>
									<p className="aProposTexte">
										Toujours à votre écoute, mon approche va au-delà des
										interventions physiques. En tant que professionnel dévoué,
										je suis là pour écouter vos besoins et vous conseiller de
										manière personnalisée. Chaque arbre a son histoire, et je
										m'efforce de trouver la meilleure solution pour le vôtre.
									</p>
								</div>
								<div className="circle-presentation circle3"></div>
								<div className="rowDiv2"></div>
							</div>
						)}
						{scrollPosition >= 0 && (
							<div className="aProposRow2">
								<div className="rowDiv1"></div>
								<div className="circle-presentation circle4"></div>
								<div className="rowDiv2">
									<p className="aProposTitre">Formateur Diplômé d'État</p>
									<p className="aProposTexte">
										En plus de mon expérience terrain, je suis également
										formateur diplômé d'État. Cela signifie que je suis
										constamment à la pointe des dernières pratiques et que
										j'aime partager mon savoir avec d'autres passionnés du
										domaine.
									</p>
								</div>
							</div>
						)}
					</div>
				</section>
			)}
			{scrollPosition >= 0 && (
				<section id="containerVideo">
					<p className="texteVideo">Quelques mots de plus : </p>
					<video
						id="videoPresentation"
						controls
						controlsList="nodownload"
						autoPlay={true}
						preload="auto"
						muted={true}
						loop
					>
						<source src={video} type="video/mp4" />
					</video>
				</section>
			)}
		</section>
	);
}

export default APropos;
