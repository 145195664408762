import "../../assets/css/Services.css";
import "../../assets/css/ServicesResp.css";
import imgEnv from "../../assets/img/serviceEnv.jpg";
import imgElag from "../../assets/img/serviceElag.jpg";
import imgHau from "../../assets/img/serviceHau.jpg";
import imgBro from "../../assets/img/serviceBro.jpg";
import imgSou from "../../assets/img/serviceSou.jpg";
import imgExp from "../../assets/img/serviceExp.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesUp } from "@fortawesome/free-solid-svg-icons";

import imgFond1 from "../../assets/img/c2.png";
const Services = ({ scrollPosition }) => {
	return (
		<section id="services">
			<>
				<div className="serviceTexte">
					<hr className="separatorSection" />
					<h3>Services</h3>
				</div>
				<img src={imgFond1} alt="" id="imageFond2" />{" "}
			</>

			<section id="servicesContainer">
				<>
					{window.innerWidth <= 1000 ? (
						<section id="servicesCard">
							<div className="row">
								<div className="cards">
									<h4>Diagnotic sanitaire</h4>
									<p>
										Évaluation approfondie de la santé de vos arbres, avec des
										recommandations pour des soins ciblés et préventifs. Inclut
										l'analyse des sols pour optimiser les conditions de
										croissance et la longévité de vos arbres.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgEnv} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>

								<div className="cards">
									<h4>Élagage et Abattage</h4>
									<p>
										Services professionnels d'élagage pour maintenir la santé et
										l'esthétique de vos arbres, ainsi que des solutions
										sécurisées d'abattage pour les arbres malades ou
										dangereusement placés.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgElag} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>
							</div>

							<div className="row">
								<div className="cards">
									<h4>Broyage et Évacuation des déchets</h4>
									<p>
										Broyage efficace des résidus de taille et abattage.
										Évacuation des déchets verts dans le respect de
										l'environnement, pour un espace extérieur propre et ordonné.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgBro} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>
								<div className="cards">
									<h4>Rognage des souches</h4>
									<p>
										Élimination des souches résiduelles pour prévenir la
										repousse et les parasites. Utilisation d'équipements
										spécialisés pour un résultat net et esthétique.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgSou} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>
							</div>

							<div className="row">
								<div className="cards">
									<h4>Haubanage de branches</h4>
									<p>
										Renforcement des branches à risque à l'aide du système Cobra
										(et autres techniques), une méthode non invasive qui
										préserve l'arbre tout en assurant la sécurité des lieux.
										Adapté aux branches charpentières et autres branches
										structurales.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgHau} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>

								<div className="cards">
									<h4>Expertise en arbres difficiles</h4>
									<p>
										Gestion spécialisée des arbres situés dans des endroits
										difficiles d'accès ou présentant des défis particuliers.
										Évaluation des risques et intervention adaptée.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgExp} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>
							</div>
						</section>
					) : (
						<section id="servicesCard">
							<div className="row">
								<div className="cards">
									<h4>Diagnotic sanitaire</h4>
									<p>
										Évaluation approfondie de la santé de vos arbres, avec des
										recommandations pour des soins ciblés et préventifs. Inclut
										l'analyse des sols pour optimiser les conditions de
										croissance et la longévité de vos arbres.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgEnv} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>

								<div className="cards">
									<h4>Élagage et Abattage</h4>
									<p>
										Services professionnels d'élagage pour maintenir la santé et
										l'esthétique de vos arbres, ainsi que des solutions
										sécurisées d'abattage pour les arbres malades ou
										dangereusement placés.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgElag} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>

								<div className="cards">
									<h4>Broyage et Évacuation des déchets</h4>
									<p>
										Broyage efficace des résidus de taille et abattage.
										Évacuation des déchets verts dans le respect de
										l'environnement, pour un espace extérieur propre et ordonné.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgBro} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>
							</div>

							<div className="row">
								<div className="cards">
									<h4>Rognage des souches</h4>
									<p>
										Élimination des souches résiduelles pour prévenir la
										repousse et les parasites. Utilisation d'équipements
										spécialisés pour un résultat net et esthétique.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgSou} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>

								<div className="cards">
									<h4>Haubanage de branches</h4>
									<p>
										Renforcement des branches à risque à l'aide du système Cobra
										(et autres techniques), une méthode non invasive qui
										préserve l'arbre tout en assurant la sécurité des lieux.
										Adapté aux branches charpentières et autres branches
										structurales.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgHau} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>

								<div className="cards">
									<h4>Expertise en arbres difficiles</h4>
									<p>
										Gestion spécialisée des arbres situés dans des endroits
										difficiles d'accès ou présentant des défis particuliers.
										Évaluation des risques et intervention adaptée.
									</p>
									<a href="#contact">En savoir plus</a>
									<div className="imageContainer">
										<img src={imgExp} alt="" />
									</div>
									<i>
										<FontAwesomeIcon icon={faAnglesUp} />
									</i>
								</div>
							</div>
						</section>
					)}
				</>
			</section>
		</section>
	);
};

export default Services;
