import "../assets/css/Contact.css";
import "../assets/css/ContactResp.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/img/logoWhite.png";
import ModalContact from "./ModalContact";

const Contact = () => {
	const close = () => {
		setModalOpen(false);
		window.scrollTo(0, 0);
	};

	const [email, setEmail] = useState("");
	const [object, setObject] = useState("");
	const [message, setMessage] = useState("");
	const [lastname, setLastname] = useState("");
	const [firstname, setFirstname] = useState("");
	const [errorEmail, setErrorEmail] = useState("");
	const [errorObject, setErrorObject] = useState("");
	const [infosContact, setInfosContact] = useState();
	const [modalOpen, setModalOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorLastname, setErrorLastname] = useState("");
	const [errorFirstname, setErrorFirstname] = useState("");
	const [errorMessageText, setErrorMessageText] = useState("");

	useEffect(() => {
		if (modalOpen) {
			// Ajoute une classe 'no-scroll' au body pour bloquer le défilement
			document.body.classList.add("no-scroll");
		} else {
			// Retire la classe 'no-scroll' pour permettre à nouveau le défilement
			document.body.classList.remove("no-scroll");
		}

		// Cleanup function pour s'assurer que la classe est retirée si le composant est démonté
		return () => {
			document.body.classList.remove("no-scroll");
		};
	}, [modalOpen]);

	const fetchData = async () => {
		const response = await axios.post(
			"https://site--pg-elagage-abattage-back--9cghzqz9dnmy.code.run/contact",
			{
				lastname: lastname,
				firstname: firstname,
				object: object,
				email: email,
				message: message,
			}
		);

		setInfosContact(response.data.contactUser);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setErrorMessage("");
		setErrorEmail("");
		setErrorFirstname("");
		setErrorLastname("");
		setErrorObject("");

		if (!lastname && !firstname && !email && !message && !object) {
			setErrorMessage(
				"Aucune information n'a été renseignée. Veuillez remplir tous les champs."
			);
			setErrorFirstname("*");
			setErrorLastname("*");
			setErrorEmail("*");
			setErrorObject("*");
			setErrorMessageText("*");
		} else {
			if (!lastname) {
				setErrorMessage("Veuillez renseigner votre nom");
				setErrorLastname("*");
			}

			if (!firstname) {
				setErrorMessage("Veuillez renseigner votre prénom");
				setErrorFirstname("*");
			}
			if (!object) {
				setErrorMessage("Veuillez renseigner l'objet de votre message");
				setErrorObject("*");
			}

			if (!email) {
				setErrorMessage("Veuillez renseigner votre email");
				setErrorEmail("*");
			}
			if (!message) {
				setErrorMessage("Veuillez renseigner votre message");
				setErrorMessageText("*");
			} else if (lastname && firstname && email && message && object) {
				fetchData();
				setModalOpen(true);
				setEmail("");
				setObject("");
				setMessage("");
				setLastname("");
				setFirstname("");
				setErrorEmail("");
				setErrorObject("");
				setErrorMessage("");
				setErrorLastname("");
				setErrorFirstname("");
				setErrorMessageText("");
			}
		}
	};

	return (
		<section id="contact">
			<section className="serviceTexte">
				<hr className="separatorSection" />
				<h3>Contact</h3>
			</section>
			<section id="contactContainer">
				<ModalContact isOpen={modalOpen} close={close} />;
				<div id="column1">
					<div id="textContact">
						<p>
							Vous avez des questions ou souhaitez obtenir un devis gratuit
							personnalisé ?
						</p>
						<p>
							N'hésitez pas à m'envoyer un message via ce formulaire ci-dessous
							ou à me contacter directement par téléphone.
						</p>
						<p>
							Je me ferai un plaisir de discuter de vos projets et de vous
							accompagner du mieux possible.
						</p>
						<div id="phoneContact">
							<i>
								<FontAwesomeIcon icon={faPhone} className="iconContact" />
								<p>06.07.89.53.93 / 03.85.79.52.63</p>
							</i>
						</div>
						<i>
							<FontAwesomeIcon icon={faPaperPlane} className="iconContact" />
							<p>pierre.gendrel@orange.fr</p>
						</i>
						<img src={logo} alt="logo" />
					</div>
				</div>
				<div id="column01">
					<form onSubmit={handleSubmit} className="containerFormInput">
						<div className="containerInput">
							<label htmlFor="nom">
								Nom :<span className="errors">{errorLastname}</span>
							</label>
							<input
								type="text"
								id="nom"
								autoComplete="off"
								placeholder="Dupond"
								value={lastname}
								onChange={(event) => {
									setLastname(event.target.value);
								}}
							/>
						</div>
						<div className="containerInput">
							<label htmlFor="prenom">
								Prénom :<span className="errors">{errorFirstname}</span>
							</label>
							<input
								type="text"
								id="prenom"
								autoComplete="off"
								placeholder="Pierre"
								value={firstname}
								onChange={(event) => {
									setFirstname(event.target.value);
								}}
							/>
						</div>

						<div className="containerInput">
							<label htmlFor="email">
								Email :<span className="errors">{errorEmail}</span>
							</label>
							<input
								type="email"
								id="email"
								value={email}
								autoComplete="off"
								placeholder="pierre.dupond@mail.com"
								onChange={(event) => {
									setEmail(event.target.value);
								}}
							/>
						</div>

						<div className="containerInput">
							<label htmlFor="object">
								Objet :<span className="errors">{errorObject}</span>
							</label>
							<input
								type="text"
								id="object"
								autoComplete="off"
								placeholder="Demande de devis"
								value={object}
								onChange={(event) => {
									setObject(event.target.value);
								}}
							/>
						</div>
						<div id="containerTextarea">
							<label htmlFor="message">
								Message : <span className="errors">{errorMessageText}</span>
							</label>
							<textarea
								id="message"
								placeholder="Veuillez entrer votre message ici"
								rows="15"
								cols="83"
								value={message}
								onChange={(event) => {
									setMessage(event.target.value);
								}}
							></textarea>
						</div>

						<p className="errorMessage">{errorMessage}</p>

						<button className="buttonForm" type="submit">
							Envoyer
						</button>
					</form>
				</div>
			</section>
		</section>
	);
};

export default Contact;
