import "../../assets/css/Main.css";
import React from "react";
import APropos from "./APropos";
import Services from "./Services";
import Galerie from "./Galerie";

function Main({ scrollPosition }) {
  return (
    <>
      <APropos scrollPosition={scrollPosition} />
      <Services scrollPosition={scrollPosition} />
      <Galerie scrollPosition={scrollPosition} />
    </>
  );
}

export default Main;
